import React from 'react'
import UserListItem from './UserListItem'
import './user-list.scss'

const icons = {
    distributor: (<i className='bx bx-receipt'></i>),
    none: (<i className='bx bx-user'></i>),
    market: (<i className='bx bx-cube'></i>),
    producer: (<i className='bx bx-dollar'></i>)
}

const ellipsis = (str) => {
    return str.substr(0, 8) + '...' + str.substr(str.length-8, str.length);
}

const UserList = ({ data }) => {

    // Sort user list in alphabetical order
    data.sort((a, b) => {
        const nameA = (a.user_metadata?.fmName || a.name).toUpperCase(); // ignore upper and lowercase
        const nameB = (b.user_metadata?.fmName || b.name).toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
    });

    console.log('data', data)
    return (
        <ul className='user-list'>
            {
                data.map((item, index) => (
                    <UserListItem 
                    item={item} 
                    index={index} 
                    key={`user-${index}`}/>
                ))
            }
        </ul>
    )
}

export default UserList
