export const COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Price Type',
      accessor: 'pricetype',
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
    },
];