import React, { useEffect, useState } from 'react'
import './main-layout.scss'
import { Outlet } from 'react-router-dom'
import { getConfig } from "../config";
import Sidebar from '../components/sidebar/Sidebar'
import TopNav from '../components/topnav/TopNav'
import Loading from '../components/Loading'
import { useAuth0 } from "@auth0/auth0-react"

const MainLayout = () => {
    const config = getConfig();
    const {
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        getAccessTokenSilently
    } = useAuth0()

    const [userData, setUserData] = useState({});
    // console.log("userData", userData)

    useEffect(() => {
        if (!user)
          return
        const getUserMetadata = async () => {
          const domain = config.domain;
      
          try {
            const accessToken = await getAccessTokenSilently({
              audience: config.audience,
              scope: "read:current_user update:current_user",
            });
      
            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            const metadataResponseJson = await metadataResponse.json();
            metadataResponseJson.accessToken = accessToken;
            // console.log("metadataResponseJson", metadataResponseJson)
      
            setUserData(metadataResponseJson);
          } catch (e) {
            console.log(e.message);
          }
        };
      
        getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <Loading />
    }

    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    if (!isAuthenticated) {
        return loginWithRedirect();
    }
      
    return (
        <>
            <Sidebar />
            <div className="main">
                <div className="main__content">
                    <TopNav />
                    <Outlet context={userData}/>
                </div>
            </div>
        </>
    )
}

export default MainLayout
