import React, { useMemo} from 'react'
import { useTable } from 'react-table'
import {COLUMNS} from './columns'
import './table.css'
 
function ProduceTable(props) {
    const columns = useMemo(() => COLUMNS, [])
    const { data, updateFunc } = props
     
    const tableInstance = useTable({
        columns,
        data
    })
 
    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow} = tableInstance
  return (
    <table {...getTableProps()}>
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}> 
                          {column.render('Header')}
                       </th>
                    ))}
                </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
                 prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                       {row.cells.map((cell) => {
                        const { id } = cell.column
                        let contents = undefined
                        if (id === 'name' || id === 'pricetype')
                            contents = cell.value
                        if (id === 'price') {
                            contents = (
                                <input 
                                    type="text" 
                                    id={`price-${index}`} 
                                    defaultValue={cell.value} 
                                    onChange={((e) => updateFunc({
                                        key: `price-${index}`,
                                        value: e.target.value
                                    }))}
                                />
                            )
                        }
                        if (id === 'enabled') {
                            contents = (
                                <select 
                                    id={`enabled-${index}`} 
                                    defaultValue={cell.value ? "yes" : "no"}
                                    onChange={((e) => updateFunc({
                                        key: `enabled-${index}`,
                                        value: e.target.value
                                    }))}
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            )
                        }
                        // console.log('cell.column.id', cell.column.id, 'cell.value', cell.value)
                            return <td {...cell.getCellProps()}>{contents}</td>
                        })}
                    </tr>
                    )
                })
            }
        </tbody>
    </table>
  )
}
 
export default ProduceTable