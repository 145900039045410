import './assets/libs/boxicons-2.1.1/css/boxicons.min.css'
import './scss/App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Blank from './pages/Blank'
import Account from './pages/Account' 
import History from './pages/History'
import Transact from './pages/Transact'
import Terms from './pages/Terms'
// import Dashboard from './pages/Dashboard'
import Home from './pages/Home'
import MainLayout from './layout/MainLayout'

function App() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<Home />} />
                    <Route path="history" element={<History />} />
                    <Route path="transact" element={<Transact />} />
                    <Route path="account" element={<Account />} />
                    <Route path="terms" element={<Terms />} />
                    {/* <Route path="stats" element={<Blank />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App
