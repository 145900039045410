import React from 'react'
import './user-summary.scss'

const icons = {
    distributor: (<i className='bx bx-receipt'></i>),
    none: (<i className='bx bx-user'></i>),
    market: (<i className='bx bx-cube'></i>),
    producer: (<i className='bx bx-dollar'></i>)
}

const UserSummary = ({ data }) => {
    const roles = Object.keys(data).sort()
    return (
        <ul className='user-summary'>
            {
                roles.map((role, index) => (
                    <li className="user-summary__item" key={`overall-${index}`}>
                        <div className="user-summary__item__icon">
                            {icons[role]}
                        </div>
                        <div className="user-summary__item__info">
                            <div className="title">
                                {role === 'none' ? 'Incomplete' : role.charAt(0).toUpperCase() + role.slice(1)}
                            </div>
                            <span>{data[role]}</span>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default UserSummary
