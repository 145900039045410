const sidebarNav = [
    {
        link: '/',
        section: 'home',
        icon: <i className='bx bx-home-alt'></i>,
        text: 'Home'
    },
    {
        link: '/transact',
        section: 'transact',
        icon: <i className='bx bx-transfer'></i>,
        text: 'Transact'
    },
    {
        link: '/history',
        section: 'history',
        icon: <i className='bx bx-receipt' ></i>,
        text: 'History'
    },
    {
        link: '/account',
        section: 'account',
        icon: <i className='bx bx-user'></i>,
        text: 'Account'
    },
    {
        link: '/terms',
        section: 'terms',
        icon: <i className='bx bx-bookmark'></i>,
        text: 'Site Terms'
    },
    // {
    //     link: '/walkthrough',
    //     section: 'walkthrough',
    //     icon: <i className='bx bx-video'></i>,
    //     text: 'Walkthrough'
    // },
    // {
    //     link: '/settings',
    //     section: 'settings',
    //     icon: <i className='bx bx-cog'></i>,
    //     text: 'Settings'
    // }
]

export default sidebarNav