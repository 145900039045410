import React, { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom";
import './admin-dash.scss'
import UserSummary from '../user-summary/UserSummary'
import UserList from '../user-list/UserList'
import ProduceTable from '../produce-table/ProduceTable';
import LoadingSmall from '../LoadingSmall';

const icons = [
    <i className='bx bx-receipt'></i>,
    <i className='bx bx-user'></i>,
    <i className='bx bx-cube'></i>,
    <i className='bx bx-dollar'></i>
]

const AdminDash = () => {

    const { 
        user_id: userId,
        accessToken
    } = useOutletContext() || {};

    const [selectedAction, setSelectedAction] = useState("")
    const [userList, setUserList] = useState(null)
    const [userSummary, setUserSummary] = useState({})

    const [produceList, setProduceList] = useState(null)

    const [fetching, setFetching] = useState(false)
    const [fetchError, setFetchError] = useState(null)

    useEffect(() => {
        if (selectedAction === "")
            return
        
        const getAdminData = async () => {
            const url = `https://api.feedingmarianas.org/v1/${selectedAction}/${userId}/${accessToken}`;
        
            const updateHistoryResponse = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if (response.status >= 400 && response.status < 600) {
                    return response.text();
                }
                return response.json();
            });

            const responseValue = await updateHistoryResponse;
            // console.log(responseValue);
            // If an error was received, display it to user
            if (typeof responseValue === 'string') {
                    setFetching(false)
                    return setFetchError(new Error(`Could not fetch ${selectedAction}. Please try again later.`));
            }
            // If response is valid, proceed
            const jsonResponse = responseValue;
            console.log("jsonResponse", jsonResponse);
            if (selectedAction === 'users') {
                const userDigest = {}
                for (let i = 0; i < jsonResponse.length; i++) {
                    const role = jsonResponse[i].user_metadata?.fmRole || 'none'
                    if (!userDigest[role])
                        userDigest[role] = 0
                    userDigest[role] ++
                }
                setUserList(jsonResponse)
                setUserSummary(userDigest)
            }
            if (selectedAction === 'produce') {
                setProduceList(jsonResponse)
            }
            setFetching(false)
        }
        
        setFetchError(null)
        if (userId && accessToken)
            setFetching(true)
            getAdminData()
    },[selectedAction]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeProduceValue = (valueObj) => {
        let { key, value } = valueObj
        const [column, index] = key.split('-')
        if (column === 'price')
            value = Number(value)
        if (column === 'enabled')
            value = value === 'yes' ? true : false
        produceList[index][column] = column === 'price' ? Number(value) : value
        // console.log('produceList', produceList)
    }

    const updateProduceList = async () => {
        setFetching(true)
        const url = `https://api.feedingmarianas.org/v1/produce/${userId}/${accessToken}`;
      
        const mintAssetResponse = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(produceList)
        }).then(response => {
            if (response.status >= 400 && response.status < 600) {
                return response.text();
            }
            return response.json();
        });
        const responseValue = await mintAssetResponse;
        // If an error was received, display it to user
        if (typeof responseValue === 'string') {
                console.log('responseValue', responseValue)
                // setFetching(false)
                return setFetchError(new Error(responseValue));
        }
        // If response is valid, proceed
        const jsonResponse = responseValue;

        console.log("jsonResponse", jsonResponse);
        setFetching(false)
    }

    const AdminDropdown = () => {
        return (
            <>
                <select
                    id="asmin-dropdown"
                    className="label"
                    value={selectedAction}
                    onChange={(e) => {
                        setSelectedAction(e.target.value)
                    }}>
                    <option value="">Select Action To Take</option>
                    <option value="users">View Users</option>
                    <option value="produce">View Produce</option>
                    {/* <option value="market">View Market Listings</option> */}
                </select>
                { fetching && <LoadingSmall />}
            </>
        );
    };

    return (
        <div className="mbm">
            <div className="subtitle">
                Action
            </div>
            <AdminDropdown />
            <div className="mbm">
                {!fetching && userList && selectedAction === 'users' && (
                    <>
                        <UserSummary data={userSummary} />
                        <UserList data={userList} />
                    </>
                )}
                {!fetching && produceList && selectedAction === 'produce' && (
                    <>
                        <ProduceTable data={produceList} updateFunc={changeProduceValue} />
                        <button 
                            type="submit"
                            onClick={() => updateProduceList()}
                        >Update</button>
                    </>
                )}
            </div>
            {fetchError && <span className="txt-error">{fetchError.message}</span>}
        </div>
    )
}

export default AdminDash
