import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

const onRedirectCallback = (appState) => {
  // TODO: push burn path + search params if burn params provided
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.href
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  ...(config.scope ? { scope: config.scope } : null),
  redirectUri: window.location.href,
  onRedirectCallback,
  useRefreshTokens: true,
	cacheLocation: "localstorage"
};

// console.log('providerConfig', providerConfig);

ReactDOM.render(
    <Auth0Provider {...providerConfig}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
