import React from 'react'
import './history-list.scss'

const icons = [
    <i className='bx bx-user'></i>,
    <i className='bx bx-transfer'></i>,
    <i className='bx bx-check'></i>,
    <i className='bx bx-receipt'></i>,
]

const ellipsis = (str) => {
    return str.substr(0, 8) + '...' + str.substr(str.length-8, str.length);
}

const HistoryList = (data) => {
    const transactionTypes = {
        mint: 'SOLD',
        receive: 'RECEIVED',
        send: 'SENT'
    }
    return (
        <ul className='overall-list'>
            {
                data.context.map((item, index) => (
                    <li className="history-list__item" key={`overall-${index}`}>
                        <div className="history-list__item__icon">
                            {item.action === 'receive' ? icons[3] : icons[1]}
                        </div>
                        <div className="history-list__item__info">
                        <span>{transactionTypes[item.action]}</span>
                            <div className="title">
                                {`${item.ticker === 'USDfm' ? '$' : ''}` +
                                `${item.transactedAmount / (10 ** item.decimals)}` +
                                `${item.ticker === 'USDfm' ? '' : item.pricetype === 'lb' ? ` lbs ${item.name}` : ` ${item.name}`}`}
                            </div>
                            <span>{(item.time === 0 ? new Date() : new Date(item.time*1000)).toLocaleString()}</span>
                            <a href={`https://explorer.e.cash/tx/${item.txid}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>{`Transaction: ${ellipsis(item.txid)}`}</span>
                            </a>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default HistoryList
