import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate } from "react-router-dom";
// import { QRCodeSVG } from 'qrcode.react';
import { getConfig } from "../config";
// import { Bar } from 'react-chartjs-2'
import Box from '../components/box/Box'
import DashboardWrapper, { DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper'
// import SummaryBoxMin, { SummaryBoxSpecial } from '../components/summary-box/SummaryBoxMin'
// import { colors } from '../constants'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import AccountList from '../components/account-list/AccountList'
import AdminDash from '../components/admin-dash/AdminDash'
import Loading from '../components/Loading'
import { 
    verifyCompleteMetadata
} from '../utils/metadata';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const Dashboard = () => {
    const navigate = useNavigate();
    const config = getConfig();
    const { 
        user_id: userId,
        user_metadata: userMetadata,
        app_metadata,
        accessToken
    } = useOutletContext() || {};

    useEffect(() => {
        if (userMetadata && !verifyCompleteMetadata(userMetadata))
            navigate("/account")
    },[userMetadata]) // eslint-disable-line react-hooks/exhaustive-deps

    const accountType = userMetadata?.fmRole.charAt(0).toUpperCase() + 
        userMetadata?.fmRole.slice(1)
    const data = {
        account: [
            {title: "Account Type", value: app_metadata?.admin ? "Administrator" : accountType},
            {title: "Full Name", value: userMetadata?.fmName},
            {title: "Phone Number", value: userMetadata?.fmPhone},
            {title: "Email Address", value: userMetadata?.fmEmail}
        ]
    }

    data.summary = [
        {value: '35', title: 'Total Burns'},
        {value: '$4302', title: 'Total Payout'}
    ] // pulled from db

    if (!accessToken) {
        return <Loading />
    }

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12">
                        {app_metadata?.admin && (
                            <div className="mb">
                                <Box>
                                    <div className="title mb">Admin Dashboard</div>
                                    <div className="mb">
                                    <AdminDash />
                                    </div>
                                </Box>
                            </div>
                        )}
                        {!app_metadata?.admin && (
                            <Box>
                                <div className="title mb">Feeding Marianas Account Information</div>
                                <div className="mb">
                                    <AccountList context={data.account} />
                                </div>
                            </Box>
                        )}
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>
    )
}

export default Dashboard