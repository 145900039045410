import React, { useState } from 'react'
import './user-list.scss'

const roles = {
    distributor: {name: 'DISTRIBUTOR', icon: (<i className='bx bx-receipt'></i>)},
    marketdistributor: {name: 'MARKET/DISTRIBUTOR', icon: (<i className='bx bx-cube'></i>)},
    none: {name:'INCOMPLETE', icon:(<i className='bx bx-user'></i>)} ,
    market: {name: 'MARKET', icon: (<i className='bx bx-cube'></i>)},
    producer: {name: 'PRODUCER', icon: (<i className='bx bx-dollar'></i>)},
    admin: {name: 'ADMIN', icon: (<i className='bx bx-pyramid'></i>)}
}

const UserListItem = ({ item, index }) => {

    const [isExpanded, setIsExpanded] = useState(false)

    const roleKey = item.app_metadata?.admin ? 'admin' : item.user_metadata?.fmRole || 'none'

    return (
        <li className="user-list__item">
            <div 
                className="user-list__item__icon"
                // onClick={() => setIsExpanded(!isExpanded)}
            >
                {roles[roleKey].icon}
            </div>
            <div className="user-list__item__info">
                {isExpanded && ( 
                    <input type="text" value={roles[roleKey].name} />
                )}
                {!isExpanded && (
                    <>
                        <span>{roles[roleKey]?.name}</span>
                            <div className="title">
                                {item.user_metadata?.fmName || item.name}
                            </div>
                        <span>{item.user_metadata?.fmEmail || item.email}</span>
                        {item.user_metadata?.fmPhone && (
                            <span>{item.user_metadata.fmPhone}</span>
                        )}
                    </>
                )}
            </div>
        </li>
    )
}

export default UserListItem
