
export function sanitizeUserMetadata (userMetadata) {
    // Reconstitute userMetadata.wire if necessary
    if (userMetadata && !userMetadata.wire) {
        if (userMetadata.type) {
            userMetadata.wire = {}
            const type = userMetadata.type;
            const metaDataKeys = Object.keys(userMetadata);
            for(let i = 0; i < metaDataKeys.length; i++) {
                const key = metaDataKeys[i];
                if (key.slice(0, type.length) === type) {
                    userMetadata.wire[key] = userMetadata[key];
                    delete userMetadata[key];
                }
            }
            userMetadata.wire.type = type;
            delete userMetadata.type;
        }
    }
    return userMetadata;
}

export function verifyCompleteMetadata (userMetadata) {
    const metadataProperties = [
        "fmName",
        "fmPhone",
        "fmRole",
        "fmTaxid",
        "fmAddress",
        "fmCitizen",
        "fmDisadvantaged",
        "fmGender",
        "fmIsland",
        "fmVeteran",
        "fmBirthdate",
        "fmEmail"
    ]

    for (let i = 0; i < metadataProperties.length; i++) {
        if(!userMetadata[metadataProperties[i]]) {
            console.log(metadataProperties[i], userMetadata[metadataProperties[i]])
            return false;
        }
    }

    return true
}