import React, { useEffect, useState } from 'react'
import { useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import { QRCodeSVG } from 'qrcode.react';
import { QrReader } from 'react-qr-reader'
import DashboardWrapper, { DashboardWrapperMain } from '../components/dashboard-wrapper/DashboardWrapper';
import Box from '../components/box/Box';
import Loading from '../components/Loading';
import LoadingSmall from '../components/LoadingSmall';
import BalanceList from '../components/balance-list/BalanceList'
import { sanitizeUserMetadata } from '../utils/metadata';

const Transact = () => {
    const { 
        user_id: userId,
        user_metadata, 
        accessToken 
    } = useOutletContext() || {};

    // console.log('userId', userId)
    // console.log('accessToken', accessToken)

    const { 
        register, 
        handleSubmit,
        resetField,
        formState: { 
            errors
        } 
    } = useForm()

    const userMetadata = sanitizeUserMetadata(user_metadata)

    const [selectedAction, setSelectedAction] = useState("")
    const [qrData, setQrData] = useState(null)
    const [qrError, setQrError] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState(null)
    const [submitResponse, setSubmitResponse] = useState(null)
    const action = register("action")
    const marketSelect = register("marketSelect")

    const [fetchError, setFetchError] = useState(null)
    const [isFetched, setIsFetched] = useState(false)
    const [balanceArray, setBalanceArray] = useState([])
    const [remoteBalanceArray, setRemoteBalanceArray] = useState([])

    const [marketChoice, setMarketChoice] = useState(null)
    const [marketData, setMarketData] = useState(null)
    const [marketError, setMarketError] = useState(null)

    const [usdAmount, setUsdAmount] = useState(0)

    // Get Market Data
    const getMarketData = async () => {
        const url = `https://api.feedingmarianas.org/v1/market`;
    
        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async response => {
            if (response.status >= 400 && response.status < 600) {
                const responseText = await response.text();
                return setMarketError(new Error(responseText));
            }
            const responseJson = await response.json();
            console.log('market data', responseJson);
            return setMarketData(responseJson);
        });
    }

    // Get Wallet Balance
    const getBalance = async (remoteId) => {
        if (remoteId)
            setMarketData([])
        else
            setIsFetched(false)
        const url = `https://api.feedingmarianas.org/v1/wallet/${userId}/${accessToken}${remoteId ? '/' + remoteId : ''}`;
    
        const updateBalanceResponse = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.status >= 400 && response.status < 600) {
                return response.text();
            }
            return response.json();
        });

        const responseValue = await updateBalanceResponse;
        console.log(responseValue);
        // If an error was received, display it to user
        if (typeof responseValue === 'string') {
                setIsFetched(true)
                return setFetchError(new Error("Could not fetch balance. Please try again later."));
        }
        // If response is valid, proceed
        const jsonResponse = responseValue.filter(b => {
            if (userMetadata?.fmRole === 'producer')
                return b.ticker === 'USDfm'
            else
                return b.ticker !== 'USDfm'
        })
        console.log("jsonResponse", jsonResponse);
        if (remoteId) {
            setMarketData(jsonResponse)
        } else {
            setBalanceArray(jsonResponse)
            setIsFetched(true)
        }
    }

    useEffect(() => {
        setFetchError(null)
        if (userId && accessToken) {
            getMarketData()
            getBalance()
        }
    },[userId]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!accessToken) {
        return <Loading />
    }

    const previewStyle = {
        height: 240,
        width: 320,
        marginBottom: '10px'
    }

    const handleQrError = (error) => {
        if (error.message) {
            setQrError(error)
            console.log(error.message)
        }
    }

    const handleQrData = (data, error) => {
        if (!!data && data?.text) {
            const splitText = data.text.split(':')
            if (splitText[0] === 'feedingmarianas' && /^[0-9a-fA-f]+$/.test(splitText[1])) {
                if(userId.split('|')[1] === splitText[1]) {
                    const qrError = new Error('Invalid QR code: You cannot buy from yourself')
                    return setQrError(qrError)
                }
                setQrData(splitText[1])
                console.log('qrData', splitText[1])
                if (['distributor', 'marketdistributor'].includes(userMetadata?.fmRole) && selectedAction === 'accept')
                    getBalance(splitText[1])
            } else {
                const qrError = new Error('Invalid QR code')
                return setQrError(qrError)
            }
        }

        if (!!error)
            handleQrError(error)
    }

    const handleBuyAmountChange = (data) => {
        const buyAmount = Number(data) * Number(marketChoice.rate)
        setUsdAmount(buyAmount.toFixed(2))
    }

    const clearForm = () => {
        //reset()
        resetField('action')
        resetField('marketSelect')
        resetField('lbs')
        setMarketChoice(null)
        setUsdAmount(0)
        setSelectedAction("")
    }

    const onSubmit = async (data) => {
        console.log(data)
        console.log(marketChoice)
        const postData = {
            tokenId: data.marketSelect,
            mintAmount: Number(data.lbs) * (10 ** marketChoice.decimals),
            userId,
            recipientId: qrData,
            accessToken
        }

        setSubmitError(null)
        setIsSubmitting(true)
        const url = `https://api.feedingmarianas.org/v1/${selectedAction === 'buy' ? 'mint/asset' : 'send'}`;
      
        const mintAssetResponse = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData)
        }).then(response => {
            if (response.status >= 400 && response.status < 600) {
                return response.text();
            }
            return response.json();
        });
        const responseValue = await mintAssetResponse;
        // If an error was received, display it to user
        if (typeof responseValue === 'string') {
                console.log('responseValue', responseValue)
                setIsSubmitting(false)
                return setSubmitError(new Error(responseValue));
        }
        // If response is valid, proceed
        const jsonResponse = responseValue;

        console.log("jsonResponse", jsonResponse);

        // Clear the form
        clearForm()
        setSubmitResponse(jsonResponse)
        setIsSubmitting(false)
        getBalance()
    }

    const validateNumber = input => !isNaN(parseInt(input) && Number(input) > 1)

    const validateLbs = (input) => /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(input)

    const validateAcceptLbs = (input) => /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(input) 
        && Number(input) <= marketChoice.balance / (10 ** marketChoice.decimals)

    // console.log(watch("example")); // watch input value by passing the name of it
    const PaymentDropdown = () => {
        const isProducer = userMetadata?.fmRole === 'producer'
        const isMarket = userMetadata?.fmRole === 'market'
        const isDistributor = userMetadata?.fmRole === 'distributor'
        const isMarketDistributor = userMetadata?.fmRole === 'marketdistributor'
        return (
            <>
                <select
                    id="payment-dropdown"
                    className="label"
                    {...action}
                    onChange={(e) => {
                        action.onChange(e);
                        if (e.target.value === 'buy') {
                            setQrError(null)
                            setQrData(null)
                        }
                        setSubmitResponse(null)
                        setSelectedAction(e.target.value)
                    }}>
                    <option value="">Select Action To Take</option>
                    {isProducer && (
                        <option value="market">Sell to Market</option>
                    )}
                    {isMarket && (
                        <>
                            <option value="buy">Buy from Producer</option>
                            <option value="distributor">Deliver to Distributor</option>
                        </>
                    )}
                    {isDistributor && (
                        <>
                            <option value="accept">Accept delivery from Market</option>
                        </>
                    )}
                    {isMarketDistributor && (
                        <>
                            <option value="buy">Buy from Producer</option>
                            <option value="accept">Accept delivery from Market</option>
                        </>
                    )}
                </select>
            </>
        );
    };

    const MarketDropdown = () => {
        return (
            <>
                <select
                    id="market-dropdown"
                    className="label mbm"
                    {...marketSelect}
                    onChange={(e) => {
                        marketSelect.onChange(e);
                        setSubmitResponse(null)
                        const asset = marketData.find(a => a.tokenId === e.target.value)
                        resetField('lbs')
                        setMarketChoice(asset)
                    }}>
                    <option value="">Select Produce Type</option>
                    { marketData.map((asset, index) => (
                        <option value={asset.tokenId} key={index}>{asset.name}</option>
                    ))}
                </select>
            </>
        )
    }

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <div>
                                {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
                                <form id="buy-form" onSubmit={handleSubmit(onSubmit)}>

                                    <div className="title mb">
                                        TRANSACT WITH FEEDING MARIANAS
                                    </div>

                                    <div className="mbm">
                                        <div className="subtitle">
                                            Action
                                        </div>
                                        <PaymentDropdown />
                                        <div className="mbm">
                                        {["market","distributor","marketdistributor"].includes(selectedAction) && (
                                            <>
                                                <div className="centered-text mb tb">
                                                    <QRCodeSVG 
                                                        value={`feedingmarianas:${userId.split('|')[1]}`} 
                                                        size={160}
                                                    />
                                                    <br />
                                                    <span className="label">
                                                        PRESENT QR CODE TO {selectedAction.replace('-', ' ').toUpperCase()} REPRESENTATIVE
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                        {["buy", "accept"].includes(selectedAction) && (
                                            <div className="mb tb">
                                                {!qrData && 
                                                    <div className="mbm centered-text">
                                                        <QrReader
                                                            onResult={handleQrData}
                                                            constraints={{
                                                                facingMode: 'environment'
                                                            }}
                                                            videoContainerStyle={{'paddingTop': '80%'}}
                                                        />
                                                        {qrError && <div className="txt-error mbm">{qrError.message}</div>}
                                                        <div className="label mbm">SCAN {selectedAction === 'buy' ? 'PRODUCER' : 'MARKET'} QR CODE TO BEGIN PURCHASE</div>
                                                    </div>
                                                }
                                                {qrData && 
                                                    <>
                                                        <MarketDropdown />
                                                        { marketChoice && (
                                                            <>
                                                                { !isSubmitting && (
                                                                    <>
                                                                        <div className="mb">
                                                                            <div className="subtitle">
                                                                                {marketChoice.name}
                                                                            </div>
                                                                            { selectedAction === 'buy' && (
                                                                                <div className="subtitle">
                                                                                    ${marketChoice.rate} / {marketChoice.pricetype}
                                                                                </div>
                                                                            )}
                                                                            <div className="label">
                                                                                Amount being {selectedAction === 'buy' ? 'purchased' : 'accepted'} (in {marketChoice.pricetype}s)
                                                                            </div>
                                                                            <div className="splitcontent">
                                                                                { selectedAction === 'buy' && (
                                                                                    <>
                                                                                        <input className="subtitle entry" 
                                                                                        {...register("lbs", {validate: validateLbs})}
                                                                                        onChange={(e) => handleBuyAmountChange(e.target.value)}
                                                                                        />
                                                                                        <div className="hspacer" />
                                                                                        <div className="subtitle hcenter">
                                                                                            ${usdAmount} paid to Producer
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                                { selectedAction === 'accept' && (
                                                                                    <>
                                                                                        <input className="subtitle entry" 
                                                                                            {...register("lbs", {validate: validateAcceptLbs})}
                                                                                            />
                                                                                        <div className="hspacer" />
                                                                                        <div className="subtitle hcenter">
                                                                                            {marketChoice.balance / (10 ** marketChoice.decimals)} available from Market
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            {errors.lbs && <span className="txt-error">Invalid. Must be valid lbs value (up to 2 decimals)</span>}
                                                                        </div>
                                                                        <input type="submit" /> 
                                                                    </>
                                                                )}
                                                                { isSubmitting && !submitError && <LoadingSmall /> }
                                                                { submitError && <div className="txt-error">Submit failed: {submitError.message}</div> }
                                                            </>
                                                        )}

                                                    </>
                                                }
                                            </div>
                                        )}
                                        {selectedAction !== "" && (
                                            <>

                                            </>
                                        )}
                                        { submitResponse?.length === 2 && submitResponse[0].result === 'success' && !submitError && 
                                            <div 
                                                className="subtitle mbm centered-text"
                                            >
                                                    Success!
                                            </div> 
                                        }
                                        </div>
                                    </div>

                                    <div className="mbm">
                                        <div 
                                            className="subtitle"
                                            onClick={() => getBalance()}
                                            onMouseOver={(e) => {e.target.style.cursor = 'pointer'}}
                                        >
                                            Current Balance  <i className='bx bx-refresh'/>
                                        </div>
                                    </div>
                                    <div className="mb">
                                        {!isFetched && !fetchError && <LoadingSmall/>}
                                        {fetchError && <span className="txt-error">{fetchError.message}</span>}
                                        {balanceArray.length > 0 && isFetched && <BalanceList context={balanceArray}/>}
                                        {balanceArray.length === 0 && isFetched && <span className="txt-error">NONE</span>}
                                        
                                    </div>
                                
                                </form>
                            </div>
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>
    )
}

export default Transact
