import React from "react";
import loading from "../assets/images/loading.svg";

const LoadingSmall = () => (
  <div className="spinnersmall">
    <img src={loading} alt="Loading" />
  </div>
);

export default LoadingSmall;
