import React, { useEffect, useState } from 'react'
import { useOutletContext } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import DatePicker from 'react-datepicker'
import Collapsible from 'react-collapsible';
import { isAddress } from "ethereum-address"
import { getConfig } from "../config";
import DashboardWrapper, { DashboardWrapperMain } from '../components/dashboard-wrapper/DashboardWrapper'
import Box from '../components/box/Box'
import Loading from '../components/Loading'
import 'react-datepicker/dist/react-datepicker.css';

const Account = () => {
    const config = getConfig();
    const { 
        user_id: userId,
        user_metadata: userMetadata, 
        accessToken 
    } = useOutletContext() || {};

    const { 
        control, 
        register, 
        handleSubmit, 
        setValue, 
        formState: { errors } 
    } = useForm()

    const [selectedRoleOption, setSelectedRoleOption] = useState("")
    const fmRole = register("fmRole")
    const fmIsland = register("fmIsland")
    const fmGender = register("fmGender")
    const fmBirthdate = register("fmBirthdate")

    useEffect(() => {
        // Set form defaults
        const metaDataKeys = Object.keys(userMetadata || {})
        if (userMetadata?.fmRole) {
            setValue("fmRole", userMetadata.fmRole)
            setSelectedRoleOption(userMetadata.fmRole)
        }
        if (userMetadata?.fmBirthdate) {
            userMetadata.fmBirthdate = new Date(userMetadata.fmBirthdate)
        }
        console.log("new usermetadata", userMetadata)
        for (let i = 0; i < metaDataKeys.length; i++) {
            if (metaDataKeys[i].slice(0,2) === 'fm')
                setValue(metaDataKeys[i], userMetadata[metaDataKeys[i]])
        }
    },[userMetadata, selectedRoleOption]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!accessToken) {
        return <Loading />
    }

    const onSubmit = async data => {
        const dataKeys = Object.keys(data)
        const userMeta = userMetadata || {}
        for (let i = 0; i < dataKeys.length; i++) {
            userMeta[dataKeys[i]] = data[dataKeys[i]]
        }
        console.log("userMeta", userMeta)
        // Update Auth0 user metadata
        const domain = config.domain;
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${userId}`;
      
        const updateMetadataResponse = await fetch(userDetailsByIdUrl, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                user_metadata: userMeta
            })
        });
        const jsonResponse = await updateMetadataResponse.json();

        console.log("jsonResponse", jsonResponse);
        window.location.assign(
            window.location.origin + window.location.pathname.replace('/account', '')
        )
    }

    const validateEmail = input => {
        if (!input || input === "")
            return false
        
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2}\w*\b)+$/.test(input)
    }

    const validatePhoneNumber = input => {
        
        return /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/.test(input)
    }

    const validateEthAddress = input => {
        return isAddress(input) || input === ""
    }

    const validateNumber = input => !isNaN(parseInt(input))

    const validateString = input => !(!input || input === "")

    // console.log(watch("example")); // watch input value by passing the name of it
    const RoleDropdown = () => {
        return (
            <select
                className="label"
                {...fmRole}
                onChange={(e) => {
                    fmRole.onChange(e);
                    setValue("fmRole", e.target.value)
                    // setSelectedRoleOption(e.target.value)
                }}>
                <option value="producer" defaultValue>Producer / Farmer</option>
                {/* <option value="market">Market</option>
                <option value="distributor">Distributor</option> */}
            </select>
        );
    };

    const IslandDropdown = () => {
        return (
            <select
                className="label"
                {...fmIsland}
                onChange={(e) => {
                    fmRole.onChange(e);
                    setValue("fmIsland", e.target.value)
                    // setSelectedRoleOption(e.target.value)
                }}>
                <option value="saipan" defaultValue>Saipan</option>
                <option value="tinian">Tinian</option>
                <option value="rota">Rota</option>
                <option value="northern">Northern Islands</option>
            </select>
        );
    };

    const GenderDropdown = () => {
        return (
            <select
                className="label"
                {...fmGender}
                onChange={(e) => {
                    fmGender.onChange(e);
                    setValue("fmGender", e.target.value)
                }}>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="na" defaultValue>Prefer Not To Say</option>
            </select>
        );
    };

    const YesNoDropdown = (props) => {
        const registered = register(props.name)
        return (
            <select
                className="label"
                {...registered}
                onChange={(e) => {
                    registered.onChange(e);
                    setValue(props.name, e.target.value)
                }}>
                <option value="yes">Yes</option>
                <option value="no" defaultValue>No</option>
            </select>
        );
    };

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <div>
                                {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
                                <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="title mb">
                                    USER INFORMATION
                                </div>

                                <div className="mbm">
                                    <div className="subtitle">
                                        Account Type
                                    </div>
                                    <div className="subtitlelo">
                                        (Select role you are applying for)
                                    </div>
                                    <RoleDropdown />
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Full Legal Name
                                    </div>
                                    <input {...register("fmName")} />
                                    {errors.fmName && <span className="txt-error">Invalid full name</span>}
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Date of Birth
                                    </div>
                                    <Controller
                                        control={control}
                                        {...fmBirthdate}
                                        render={({ field }) => (
                                            <DatePicker
                                                placeholderText='MM/DD/YYYY'
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        )}
                                    />
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Phone Number
                                    </div>
                                    <input {...register("fmPhone", {validate: validatePhoneNumber})} />
                                    {errors.fmPhone && <span className="txt-error">Invalid phone number</span>}
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        E-mail address
                                    </div>
                                    <input {...register("fmEmail", {validate: validateEmail})} />
                                    {errors.fmEmail && <span className="txt-error">Invalid email address</span>}
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Social Security Number
                                    </div>
                                    <div className="subtitlelo">
                                        (Numbers only. No letters or other characters.)
                                    </div>
                                    <input {...register("fmTaxid", {validate: validateNumber})} />
                                    {errors.Taxid && <span className="txt-error">Invalid e-mail address</span>}
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Island
                                    </div>
                                    <IslandDropdown />
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Mailing Address
                                    </div>
                                    <input {...register("fmAddress", {validate: validateString})} />
                                    {errors.fmAddress && <span className="txt-error">Invalid mailing address</span>}
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        What is your gender?
                                    </div>
                                    <GenderDropdown />
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Are you a socially disadvantaged producer/distributor?
                                    </div>
                                    <YesNoDropdown
                                        name="fmDisadvantaged"
                                    />
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Are you a veteran or active military?
                                    </div>
                                    <YesNoDropdown
                                        name="fmVeteran"
                                    />
                                </div>

                                <div className="mb">
                                    <div className="subtitle">
                                        Do you certify, under penalty of perjury, that you are a US citizen?
                                    </div>
                                    <div className="subtitlelo">
                                        (You may be contacted to verify your citizenship)
                                    </div>
                                    <YesNoDropdown
                                        name="fmCitizen"
                                    />
                                </div>

                                <div className="label">
                                    By submiting your information you acknowledge that understand that your participation in the LFPA program is
                                    voluntary. You will abide by the terms/conditions set forth by USDA's Agricultural Marketing
                                    Service (AMS) regarding this award. You acknowledge that USDA and the CNMI Government
                                    will not indemnify you for any loss, injury or harm incurred while participating in this program,
                                    nor will USDA or the CNMI Government be held liable for any adverse events that may arise, up
                                    to and including crop loss/damage, food-borne illness, etc. You understand that your
                                    participation can be revoked without notice at any time for any reason up to and including
                                    fraud/waste/abuse. The CNMI Government may pursue financial restitution, including
                                    repayment of funds, should it be discovered a producer/market/distributor engaged, or intended to
                                    engage, in fraudulent activity for financial gain. Revenue generated from the LFPA program may
                                    be considered taxable income. Please consult your accountant/tax attorney for any legal
                                    questions.
                                </div>
                                
                                <input type="submit" />
                                </form>
                            </div>
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>
    )
}

export default Account
