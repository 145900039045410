import React from 'react'
import './topnav.scss'
import UserInfo from '../user-info/UserInfo'
import { useAuth0 } from "@auth0/auth0-react"

const TopNav = () => {

    const {
        user
    } = useAuth0()

    const openSidebar = () => {
        document.body.classList.add('sidebar-open')
    }

    return (
        <div className='topnav'>
            {user && <UserInfo user={user} />}
            <div className="sidebar-toggle" onClick={openSidebar}>
                <i className='bx bx-menu-alt-right'></i>
            </div>
        </div>
    )
}

export default TopNav
