import React from 'react'
import './balance-list.scss'

const BalanceList = (data) => {

    return (
        <ul className='overall-list'>
            {
                data.context.map((item, index) => (
                    <li className="history-list__item" key={`overall-${index}`}>
                        <div className="history-list__item__icon">
                            <i className='bx bx-receipt'></i>,
                        </div>
                        <div className="history-list__item__info">
                            <a href={`https://explorer.e.cash/address/${item.address}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="title">
                                    {`${item.ticker === 'USDfm' ? '$' : ''}` +
                                    `${item.balance / (10 ** item.decimals)}` +
                                    `${item.ticker === 'USDfm' ? '' : ` lbs ${item.name}`}`}
                                </div>
                            </a>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default BalanceList
