import React from 'react'
import DashboardWrapper, { DashboardWrapperMain } from '../components/dashboard-wrapper/DashboardWrapper'
import Box from '../components/box/Box'

const Terms = () => {
    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <div className="title mb">Privacy Policy and Terms of Service</div>
                            <div>
                                Hafa Adai, Tirow and Welcome!<br /><br />
                                Thank you for visiting the CNMI’s Local Food Purchase Assistance (LFPA) Program website. Your privacy
                                is important to us. To better protect your privacy, we provide this notice explaining our online
                                information practices and the choices you can make about the way your information is collected and
                                used on our site.<br /><br />
                                The Information We Collect<br /><br />
                                You provide specific personal information on our website to become a participant in the LFPA program.
                                The information includes: your name, address, e-mail address, telephone number, Social Security
                                Number, etc. We will also collect certain non-personal information including socially-disadvantaged
                                status, military background, etc. General demographic information is reported to USDA under the terms
                                of the LFPA award.<br /><br />
                                How We Use the Information<br /><br />
                                We use the information you provide to set up your account for participation in the LFPA program. We
                                may disclose personal information in response to legal process (e.g. in response to a court order or
                                subpoena). We also may disclose such information in response to a law enforcement agency&#39;s request.<br /><br />
                                Terms of Service<br /><br />
                                MARKETS will upload a photo of the received produce on a scale to verify the proper weight and
                                produce type has been received by them. Markets are required to report general information to the
                                Dept of Commerce as to whom the produce was prepared for or given to. Markets reserve the right
                                to refuse produce for any reason.<br /><br />
                                FARMERS/RANCHERS will deliver their product to a market directly.
                                Farmers/ranchers will not be given credit for the product until the market successfully uploads a
                                photo showing the weight on the scale and the produce received. markets will not compensate
                                producers directly as all payments will be made by the CNMI Dept of Commerce.<br /><br />
                                DISTRIBUTORS will deliver their product to the consumer directly. Distributors will accept goods from markets.<br /><br />
                                Payment Schedule<br /><br />
                                Producers will be paid via check on the 1 st and 15 th of every month so long as they have a minimum
                                balance of $100 in their account. Checks will be issued by a third party working in conjunction with Dept
                                of Commerce to implement the award.<br /><br />
                                Producers may be capped at $1,000.00 in purchases and subject to a temporary “blackout” period to
                                allow other producers an opportunity to participate. One of the primary objectives of the CNMI LFPA
                                program is to attract beginning farmers to agriculture and increase the number of producers in our
                                Territory. Priority will be given to backyard- and beginning farmers in order to achieve food security
                                goals in the Commonwealth.
        </div>
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>
    )
}

export default Terms
