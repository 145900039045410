import React from 'react'
import './account-list.scss'

const icons = [
    <i className='bx bx-detail'></i>,
    <i className='bx bx-user'></i>,
    <i className='bx bx-phone'></i>,
    <i className='bx bx-envelope'></i>,
    <i className='bx bx-receipt'></i>,
]

const AccountList = (data) => {
    return (
        <ul className='overall-list'>
            {
                data.context.map((item, index) => (
                    <li className="overall-list__item" key={`overall-${index}`}>
                        <div className="overall-list__item__icon">
                            {icons[index]}
                        </div>
                        <div className="overall-list__item__info">
                            <div className="title">
                                {item.title}
                            </div>
                            <span>{item.value && item.value.length > 0 ? item.value : "None Specified"}</span>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default AccountList
