import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate } from "react-router-dom";
import DashboardWrapper, { DashboardWrapperMain } from '../components/dashboard-wrapper/DashboardWrapper'
import Box from '../components/box/Box'
import HistoryList from '../components/history-list/HistoryList'
import LoadingSmall from '../components/LoadingSmall'
import { 
    verifyCompleteMetadata
} from '../utils/metadata';

const History = () => {
    const navigate = useNavigate();
    const { 
        user_id: userId,
        user_metadata: userMetadata,
        accessToken 
    } = useOutletContext() || {};

    const [fetchError, setFetchError] = useState(null)
    const [isFetched, setIsFetched] = useState(false)
    const [historyArray, setHistoryArray] = useState([])

    useEffect(() => {
        if (userMetadata && !verifyCompleteMetadata(userMetadata))
            navigate("/account")
        // Get BURN Payment Request URL
        const getHistory = async () => {
            const url = `https://api.feedingmarianas.org/v1/history/${userId}/${accessToken}`;
        
            const updateHistoryResponse = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if (response.status >= 400 && response.status < 600) {
                    return response.text();
                }
                return response.json();
            });

            const responseValue = await updateHistoryResponse;
            console.log(responseValue);
            // If an error was received, display it to user
            if (typeof responseValue === 'string') {
                    setIsFetched(true)
                    return setFetchError(new Error("Could not fetch history. Please try again later."));
            }
            // If response is valid, proceed
            const jsonResponse = responseValue;
            // console.log("jsonResponse", jsonResponse);
            setHistoryArray(jsonResponse)
            setIsFetched(true)
        }
        
        setFetchError(null)
        if (userId && accessToken)
            getHistory()
    },[userId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <div className="title mb">Transaction History</div>
                            <div className="mb">
                                {fetchError && <span className="txt-error">{fetchError.message}</span>}
                                {historyArray.length > 0 && <HistoryList context={historyArray}/>}
                                {historyArray.length === 0 && isFetched && <span className="txt-error">NONE</span>}
                                { !isFetched && <LoadingSmall />}
                            </div>
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>
    )
}

export default History
